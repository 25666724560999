import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {
  static targets = [ "click", "provider", "desc", "database" ]

  click() {
    let params = new URLSearchParams()
    params.append("deployment[description]", this.descTarget.value)
    params.append("deployment[provider]", this.providerTarget.value)

    get(`/deployments/new?${params}`, {
      responseKind: "turbo-stream"
    })
    // Need to empty all of the form fields before doing anything and then can
    // submit again
    //this.clickTargets.forEach(target => target.click())
  }

  db_click() {
    let params = new URLSearchParams()

    // Get the description and the provider values
    params.append("deployment[description]", this.descTarget.value)
    params.append("deployment[provider]", this.providerTarget.value)
    params.append("deployment[database]", this.databaseTarget.value)

    get(`/deployments/new?${params}`, {
      responseKind: "turbo-stream"
    })
  }
}
